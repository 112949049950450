import { create } from '@storybook/theming/create';

export default create({
  base: 'dark',

  brandTitle: 'MeetMo.io',
  brandUrl: 'http://localhost:3000/dashboard/',
  brandImage: '/assets/images/MeetMo.svg',
  brandTarget: '_self',
});
